export const tabChange = (selector) => {
  const key = `.${selector} .tab-contents .active, .${selector} .dropdown-tabs .active`;
  try {
    document.querySelectorAll(key).forEach((el) => {
      const video = el?.querySelector('video');
      if (video) video.currentTime = 0;
    });
  } catch {
    // eslint-disable-next-line no-console
    console.warn('Error: video not available');
  }
};

export default { tabChange };
